<template>
  <TaskDetailHeaderComponent :functionalId=functionalId :clientId=clientId :projectId=projectId />
</template>

<script>
import TaskDetailHeaderComponent from "@/components/taskDetail/TaskDetailHeaderComponent.vue";

export default {
  name: "TaskDetail",
  components: {
    TaskDetailHeaderComponent
  },
  props: {
    functionalId: {
      type: String,
      required: true
    },
    projectId: {
      type: String,
      required: true
    },
    clientId: {
      type: String,
      required: true
    }
  }
}
</script>